<template>
  <div class="map" v-if="!!getEvents">

      <PlaceForm
          v-if="selectedEvent"
          @close="closeOrderForm"
      />

    <div class="map--select" v-if="!isMobile">
      <v-select
          :items="getTypesEvents"
          label="Все типы площадок"
          hide-details
          dense
          item-text="type"
          item-value="id"
          v-model="type_of_event"
          @change="selectPlaceType"
          solo
      ></v-select>
    </div>
    <div
      v-if="false"
      class="map--checkbox"
    >
      <v-checkbox
          v-model="checkbox"
          label=" Доступно сегодня"
          hide-details
          dense
      ></v-checkbox>
    </div>

    <div class="map__container" v-if="!isMobile">
      <yandex-map
          :coords="coords"
          :zoom="15"
          :controls="[]"
          v-if="!isMobile"

      >
        <ymap-marker
            v-for="item of getEvents"
            :key="item.id"
            @click="selectEvent(item)"
            :coords="[item.latitude, item.longitude]"
            :marker-id="item.id"
            :icon="customIcon(item.type.type_id)"
        />
      </yandex-map>
    </div>
  </div>
</template>
<script>

import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import {mapGetters, mapActions} from 'vuex'
import PlaceForm from "@/components/PlaceForm";

export default {
  name: 'Map',
  components: {yandexMap, ymapMarker, PlaceForm},
  data: () => ({
    select: null,
    type_of_event: 0,

    checkbox: false,
    selectedPlace: {},
    coords: [
      55.796127, 49.106414
    ],
    showPlaceForm: false,
    isMobile: false,
  }),
  computed: {
    ...mapGetters({
      getEvents: 'events/getEvents',
      getTypesEvents: 'events/getTypesEvents',
      selectedEvent: 'events/getSelectedEvent'
    })
  },
  async created() {
    if (window.innerWidth < 760) {
      this.isMobile = true;
    }
    window.addEventListener('resize', this.onWidthChange)
  },
  methods: {
    ...mapActions({
      loadEvents: 'events/load_events',
      setSelectedEvent: 'events/set_selected_event'
    }),
    async onWidthChange(e) {
      if (e.currentTarget.innerWidth < 760) {
        this.isMobile = true;
        return;
      }

      this.isMobile = false;
    },
    selectPlaceType() {
      this.loadEvents({
        from_date: this.$moment().format('YYYY-MM-DD'),
        type_id: this.type_of_event
      })
      this.setSelectedEvent(null)
    },
    async selectEvent(event) {
      await this.setSelectedEvent({id: event.id, date: this.$moment().format('YYYY-MM-DD')})
      this.showPlaceForm = true;
    },
    closeOrderForm() {
      this.setSelectedEvent(null);
      this.showPlaceForm = false;
      this.selectedPlace = {};
    },
    customIcon(type_id) {
      let icon;
      if (type_id) {
        icon = require('@/assets/svg/pins/category_' + type_id + '.svg');
      } else {
        icon = require('@/assets/svg/pins/default.svg');
      }

      return {
        layout: 'default#image',
        imageSize: [40, 60],
        imageHref: icon
      };
    }
  },
}
</script>

<style lang="scss">
.v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after {
  visibility: hidden !important;
}

.v-select__selection--comma {
  overflow: inherit !important;
}

.ymap-container {
  width: 100%;
  height: 100%;
}


.map {
  position: relative;

  &__container {
    width: 100%;
    height: 714px;
    position: relative;

    &.height-max {
      height: max-content;
    }

    @media screen and (max-width: 1439px) {
      height: 614px;

      .ymaps-2-1-79-map {
        height: 100% !important;
      }
    }

    @media screen and (max-width: 768px) {
      height: 750px;

      .ymaps-2-1-79-map {
        height: 100% !important;
      }
    }
  }


  &--place-form {
    position: absolute;
    top: 24px;
    right: 96px;
    z-index: 2;
    height: 665px;
    width: 45%;

    @media screen and (max-width: 1439px) {
      right: 40px;
      height: 594px;

    }

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 595px;
      right: auto;
      left: 0px;
      top: 150px;
    }

  }

  &--checkbox {
    width: 200px;
    height: 52px;
    position: absolute;
    z-index: 2;
    top: 24px;
    left: 456px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-size: 14px;


    @media screen and (max-width: 1439px) {
      width: 239px;
      left: 40px;
      top: 84px;

    }

    @media screen and (max-width: 768px) {
      width: 239px;
      left: 20px;

    }
  }

  &--select {
    position: absolute;
    z-index: 2;
    top: 24px;
    left: 80px;
    right: auto;
    width: 372px;
    height: 52px;


    @media screen and (max-width: 1439px) {
      width: 239px;
      left: 40px;
    }

    @media screen and (max-width: 768px) {
      width: 239px;
      left: 20px;
      overflow: hidden;
    }

    .v-input {
      height: 100%;

      .v-input__control {
        height: 100%;

        .v-input__slot {
          height: 100%;
        }
      }
    }
  }

  .ymap-container {
    position: relative;
    z-index: 1;
  }

}
</style>
