let domain = process.env.VUE_APP_API_URL || 'https://test.artists.kzn.kirarock.space';
import axios from "axios";

window.axiosInstance = axios.create()

export const api = {
  domain: domain,
  events: {
    get_events: async (date, type_id = null) => {
      return axiosInstance({
        method: 'get',
        url: `${domain}/api/events`,
        params: {
          date: date,
          type_id: type_id
        },
      })
    },
    set_event: async (eventID, item ) => {
      return axiosInstance({
        method: 'post',
        url: `${domain}/api/events/${eventID}/request`,
        data: {
          file: item.file,
          time_id: item.time_id,
          email: item.email,
          fio: item.fio,
          phone: item.phone,
          members: item.members,
          team_name: item.team_name,
          date: item.date,
          category: item.category,
          smart_token: item.smart_token
        }
      })
    },
    deleteEvent: async(artEventRequestId) => {
      return axiosInstance({
        method: 'delete',
        url: `${domain}/api/request/${artEventRequestId}`
      })
    },
    get_type_events: async () => {
      return axiosInstance({
        method: 'get',
        url: `${domain}/api/types`,
      })
    },
    get_event: async (eventId, date) => {
      return axiosInstance({
        method: 'get',
        url: `${domain}/api/events/${eventId}`,
        params: {
          date: date,
        }
      });
    },
  },
  registration: {
    post: async (name, email, password) => {
      const data = {
        name: name,
        email: email,
        password: password,
        agree_to_data_processing: 'true'
      }
      return await axiosInstance.post(`${domain}/api/register`, data)
    }
  },
  login: {
    post: async ( email, password) => axios({
      method: 'post',
      url: `${domain}/api/login`,
      params: {
        email,
        password
      }
    }),
    getAuthLink: async() => axios({
      method: 'get',
      url: `${domain}/api/esia/login`
    })
  },
  logout: {
    post: async() => axiosInstance({
      method: 'post',
      url: `${domain}/api/logout`
    })
  },
  profile: {
    put: async(item) => axiosInstance({
      method: 'put',
      url: `${domain}/api/profile`,
      params: {
        phone: item.phone,
        name: item.name,
        email: item.email
      }
    }),
    get: async() => axiosInstance({
      method: 'get',
      url: `${domain}/api/profile`,
    }),
  },
  banner: {
    get: async() => axiosInstance({
      method: 'get',
      url: `${domain}/api/banner`,
    }),
  }
};
